body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    font-size: 22px;
    font-family: sans-serif;
    text-align: center;
}

body.correct {
    background-color: rgb(0, 192, 118, 0.05);
}

button {
    text-transform: uppercase;
    background-color: white;
    color: black;
    padding: 0.8rem;
    border: 1px solid silver;
}

.schedule-button {
    position: fixed;
    top: 2rem;
    right: 1rem;
    border: 0;
    margin: 0;
    padding: 2px;
    background-color: white;
    height: 2rem;
    width: 2rem;
    box-sizing: border-box;
    cursor: pointer;
}

.schedule-button > span {
    display: inline-block;
    height: 2px;
    background-color: silver;
    position: absolute;
    left: 10%;
    right: 10%;
    top: 20%;
}

.schedule-button > span:nth-child(2) {
    top: calc(50% - 1px);
}

.schedule-button > span:nth-child(3) {
    top: calc(80% - 2px);
}

.page-container {
    text-align: center;
    padding: 1rem;
    margin: auto;
    max-width: 80rem;
}

.page {
    padding: 6rem 0;
    margin: auto;
    text-align: left;
}

@media screen and (max-width: 800px) {
    .page {
        padding: 1rem 0;
    }
}

.quesstion-strike {
    display: block;
    word-break: break-all;
    font-size: 0.666rem;
    margin-bottom: 1rem;
}

.question-element {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.answer-field {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid silver;

    &:disabled {
        pointer-events: none;
        background-color: grey;
    }
}

.yes-no-container {
    display: flex;
}

.yes-no-button {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 0.2rem;
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border: none;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-color: mediumpurple;
    height: 3rem;
    border-radius: 0.5rem;
}

.yes-no-button:last-child {
    margin-right: 0;
}
.yes-no-button--no {
    background-color: crimson;
}

.yes-no-button--yes {
    background-color: mediumseagreen;
}

.yes-no-button:hover {
    background-color: blueviolet;
}

.submit-answer-button-container {
    padding-top: 0.5rem;
}

.submit-answer-button {
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border: none;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-color: mediumpurple;
    height: 3rem;
    border-radius: 0.5rem;
}

.submit-answer-button:hover {
    background-color: blueviolet;
}

.submit-answer-button--success,
.submit-answer-button--success:hover {
    background-color: mediumseagreen;
}

.submit-answer-button--danger {
    background-color: crimson;
}

.submit-answer-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.answer-element {
    display: block;
    padding: 1rem 1rem;
    font-size: 1.5rem;
}

.modal-overlay {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-overlay--true {
    display: block;
}

.modal {
    overflow: hidden;
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.modal__header {
    text-align: center;
    padding: 1rem;
    flex-grow: 0;
    flex-grow: 0;
    box-shadow: 0 0 4px silver;
    user-select: none;
    -webkit-user-select: none;
}

.modal__header * {
    user-select: none;
    -webkit-user-select: none;
}

.modal-tab-button {
    margin: 0;
    background-color: white;
    border: 1px solid silver;
    padding: 0.5rem;
    vertical-align: middle;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.modal-tab-button:hover {
    background-color: blueviolet;
}

.modal-tab-button--active {
    background-color: mediumpurple;
    color: white;
}

.modal-tab-button--left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-right: 0;
}

.modal-tab-button--right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-left: 0;
}

.modal-tab-button--middle {
    border-left: 0;
    border-right: 0;
}

.modal-close-button {
    background-color: white;
    border: none;
    position: relative;
    height: 2rem;
    width: 2rem;
    vertical-align: middle;
    float: right;
    cursor: pointer;
}

.modal-close-button > span {
    display: inline-block;
    height: 2px;
    background-color: silver;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: rotate(-45deg)
}

.modal-close-button > span:last-child {
    transform: rotate(45deg)
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem 1rem;
    padding-bottom: 4rem;
}

.schedule-list {
    padding: 0;
    margin: 0;
}

.schedule-list__item {
    margin: 0;
}

.schedule-list__date {
    color: gray;
    font-size: 0.8rem;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: none;
}

.schedule-list__date--highlight {
    color: black;
    font-weight: 700;
}

.schedule-list__date--today {
    color: blueviolet;
}

.schedule-list__count {
    margin: 0;
    padding: 0;
    text-align: right;
    padding: 0 0.5rem;
    border: none;
}

.schedule-list__bar {
    margin: 0;
    padding: 0;
    display: inline-block;
    height: 1rem;
    box-sizing: border-box;
    background-color: mediumpurple;
    padding: 0;
    border: none;
}

.unscheduled-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
}

.on-hold-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
}

.unscheduled-list__item {
    margin-top: 0.5rem;
}

.on-hold-list__checkbox,
.unscheduled-list__checkbox {
    width: 1.4rem;
    height: 1.4rem;
    vertical-align: middle;
}

.modal-actions {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}

.modal-action-button {
    padding: 1rem;
    font-size: 1.4rem;
    box-sizing: border-box;
    border: none;
    border: 1px solid mediumpurple;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-color: mediumpurple;
    color: white;
    margin-left: 0.4rem;
}

.modal-action-button:hover {
    background-color: blueviolet;
}

.keyboard-list {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 1rem;
}

.keyboard-item {
    display: inline-block;
    padding: 0.62rem;
    min-width: 1rem;
    text-align: center;
    margin-right: 0.1rem;
    margin-bottom: 0.4rem;
    background-color: #BDB0D9;
    border-radius: 0.4rem;
    cursor: pointer;
}

.add-field {
    width: 100%;
    min-height: 15vh;
    font-size: 1rem;
    padding: 1em;
    box-sizing: border-box;
    border: 1px solid silver;
}

.add-field--answer {
    min-height: 2em;
}

.add-question-source-target-container {
    display: flex;
    vertical-align: center;
    width: 100%;
}

.login-input,
.add-question-source-target-container > input {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    border: 1px solid silver;
    font-size: 1rem;
    padding: 1em;
    min-width: 0;
}

.both-ways-button {
    padding: 0 1rem;
    font-size: 1.6rem;
    margin: 0 0.2rem;
}

.st-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    margin-top: 0.2rem;
    justify-content: flex-end;
}

.st-list > li {
    margin: 0 0.1rem;
}

.st-list > li:last-child {
    margin-right: 0;
}

.conjugation-list {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    list-style: none;
    margin-top: 1rem;
}

.conjugation-list__tense-name {
    font-weight: 700;
    text-transform: uppercase;
}

.conjugation-list__tense-list {
    list-style: none;
    padding: 0;
    padding-bottom: 1rem;
}

.login-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;

    background-color: rgba(40, 40, 40);

    padding: 5rem;
}

#loginInputButton {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1rem;
}

.login-input {
    width: 100%;
    box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: black;
        color: white;
    }

    body.correct {
        background-color: #00492D;
    }

    .schedule-button {
        background-color: black;
        color: white;
    }

    .schedule-list__date--highlight {
        color: silver;
    }

    .modal {
        background-color: black;
        color: white;

    }

    .modal-tab-button {
        background-color: black;
        color: white;
    }

    .modal-tab-button--active {
        background-color: blueviolet;
    }

    .modal-close-button {
        background-color: black;
        color: white;
    }

    .answer-field {
        background-color: #1F1F1F;
        color: white;
        border: 1px solid #2A2A2A;
    }

    .modal-overlay {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .keyboard-item {
        background-color: #3A3642;
    }

    textarea {
        background-color: black;
        color: white;
    }

    input[type=text] {
        background-color: black;
        color: white;
    }

    button {
        background-color: black;
        color: white;
        padding: 0.8rem;
        border: 1px solid silver;
    }

    .error {
        background-color: black;
    }
}

.image-question {
    max-width: 100%;
}


.answer-image__option {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 1%;
}


.error {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;
}

@media (prefers-color-scheme: dark) {
    .error {
        background-color: black;
    }
}

nav.fixed-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    max-width: 80rem;
    background-color: white;
    border-bottom: 1px solid silver;
    padding: 1rem;
    box-sizing: border-box;

    @media (prefers-color-scheme: dark) {
        background-color: black;

    }
}

.nav-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: space-between;
}

.nav-list__item {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: all 0.5s ease-in-out;
}

.nav-list__item:hover {
    opacity: 1.0;
}

.nav-list__item--active {
    background-color: blueviolet !important;
    opacity: 1;
}

.nav-list__item__a {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.annoted-text__annotation {
    width: 100%;
    font-size: 1rem;
    line-height: 1.8rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border: 1px solid silver;
}

.annoted-text__text {
    width: 100%;
    font-size: 1rem;
    line-height: 1.8rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 7rem;
    border: 1px solid silver;

}

.annoted-text__annotations-container {
    padding: 0.5rem 0;
    display: flex;
    align-items: stretch;

}

.annoted-text__annotations-container > button {
    margin-right: 0.5rem;
}

.add-page__bidirectional-button {
    width: 100%;
    margin: 0.5rem 0;
    background-color: blueviolet;
    font-weight: 700;
    color: white;
    font-size: 1.2rem;
}

.question__image {
    max-width: 100%;
}

.answer-text--submitting,
.answer-image--submitting,
.answer-map--submitting {
    opacity: 0.7;
}

.answer-text__prefix-buttons-container {
    padding: 1rem 0;
    display: flex;

    button {
        flex-basis: 0;
        flex-grow: 1;
        cursor: pointer;
        border: 1px solid silver;
        border-right: 0;
    }

    button:hover {
        background-color: mediumpurple;
        color: white;
    }

    button:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    button:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid silver;
    }
}

.spinner {
    content: '🐺';
    font-size: 4rem;
    animation:spin 4s linear infinite;
}

@keyframes spin { 100% { transform:rotate(360deg); } }

.open-question-button {
    padding: 0.25rem;
}

.questions-list {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 1rem;
    }

    button {
        margin-right: 1rem;
    }
}