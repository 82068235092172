.map {
    &__element--selected {
        * {
            fill: red;
        }
    }
}


.map--clickable {
    g:hover {
        * {
            opacity: 0.5;
        }
    }
}
